import React, {useEffect} from "react";
import Seo from "../components/seo"
import * as R from 'ramda';
import {useIsMobile} from "../utils";
import {Product} from "../models/product";
import Hero from "../components/hero";
import Story from "../components/story";
import {client} from "../client";
import {navigate} from "gatsby";


const Subscribe = () => {
        const {isMobile, screenWidth} = useIsMobile();

        useEffect(() => {
            client.request(`
          query {
  products(first: 100, query:"product_type:咖啡訂閱") {
    edges {
      node {
        id
        tags
        handle
        title
        availableForSale
        variants(first: 50) {
          edges {
            node {
              id
              title
              priceV2 {
                amount
              }
            }
          }
        }
        images(first: 10) {
          edges {
            node {
              url(transform: { 
                crop: CENTER
                maxWidth: 500,
                maxHeight: 500})
              id
              thumbnailUrl: url(transform: { 
                crop: CENTER
                maxWidth: 360,
                maxHeight: 360})
            }
          }
        }
      }
    }
}
          }
        `).then((data) => {
                console.log(
                    data.products.edges.map((e) => Product.fromShopify(e))
                );
            });

        }, [])


    return (
        <div>
            <Seo title="訂閱咖啡豆"/>
            <Hero imageUrl={'/subscribe-pic1.jpg'}>
                <div style={{display: 'flex', flexDirection: 'column', fontFamily: 'Genwan, "Noto Serif TC", "serif" ', alignItems: 'center'}}>
                    <div style={{
                            fontSize: 32,
                            padding: '10% 0 0 0',
                            color: '#000',
                        }}>
                            訂閱咖啡豆
                        </div>
                        <div style={{height: 16}}/>
                        <div
                            style={{
                                color: '#000',
                                width: '33%',
                                fontSize: 18,
                                textAlign: 'justify',
                            }}
                        >
                            合作咖啡提供咖啡豆訂閱服務，讓咖啡愛好者每個月輕鬆收到最新鮮的咖啡豆，適合家庭、公司等用量較大的客戶。
                        </div>
                    </div>
                </Hero>
                <div style={{height: 64}}/>
                <Story
                  imageUrl={'https://cdn.shopify.com/s/files/1/0645/0436/5310/files/subscribe-2.jpg?width=1024'}
                    header="訂閱方式"
                    content={
                        <div style={{
                            display: 'flex', flexDirection: 'column'
                        }}>
                            <p>
                                1.每個月的第一周收到我們的咖啡豆。
                                <br/>
                                2.每個月的15號前訂閱，當月就可以收到我們的咖啡豆。
                                <br/>
                                3.每個月的15號後訂閱，會在下個月的第一周收到我們的咖啡豆。
                                <br/>
                                4.國外的訂閱客戶會依照當下航運運送狀況，告知最後送達時間。
                            </p>
                            <div style={{fontWeight: 'bold'}}>
                                沖煮器具與研磨:
                            </div>
                            <p>
                                告訴我們您使用的器具，我們可以提供研磨服務。
                            </p>
                            <div style={{fontWeight: 'bold'}}>
                                養豆時間:
                            </div>
                            <p>
                                手沖養豆時間:建議 7 天
                                <br/>
                                濃縮養豆時間:建議 14 天
                            </p>
                            <div style={{fontWeight: 'bold'}}>
                                運輸費用和方式:
                            </div>
                            <p>
                                運費不包含在咖啡豆價格中，會在會後結帳時計算。
                                <br/>
                                國內可以選擇店到店、中華郵政以及宅急便，國外皆使用中華郵政寄送。
                            </p>
                            <div style={{fontWeight: 'bold'}}>
                                取消訂閱:
                            </div>
                            <p>
                                如果需要取消訂閱，請在每個月的15號前寄電子郵件或打電話給我們，我們會幫你取消。
                            </p>
                        </div>
                    }
                />
                <div style={{height: 64}}/>
                <div style={{
                    width: '100%',
                    flexDirection: 'column',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>

                    <div style={{
                        width: '68%',
                        flexDirection: 'column',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginBottom: 128,
                    }}>
                        <div style={{fontSize: 28,}}>
                            選擇咖啡豆
                        </div>
                        <div
                            style={{width: '100%', height: 40}}
                        ></div>
                        <div style={{
                            width: '100%',
                            flexDirection: isMobile ? 'column' : 'row',
                            display: 'flex',
                            gap: 16,
                            justifyContent: 'center'
                        }}>
                            {
                                R.pipe(
                                    R.map(
                                        (e) => (
                                            <div
                                                style={{
                                                    alignItems: 'center',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    cursor: 'pointer'
                                                }}
                                                onClick={() => {
                                                  navigate(e.productUrl);
                                                }}
                                            >
                                                <div style={{
                                                    flexGrow: 1,
                                                    display: 'flex',
                                                    padding: isMobile ? 16 : '4rem',
                                                    alignItems: 'center',
                                                    flexDirection: 'column',
                                                    border: '1px solid',
                                                    borderRadius: '6px'
                                                }}>
                                                    <img 
                                                      src={e.imageUrl}
                                                      style={{ width: 300, height: 320, objectFit: 'cover' }}
                                                    ></img>
                                                    <div style={{ height: 32 }} />
                                                    <div
                                                        style={{
                                                            fontSize: 12
                                                        }}
                                                    >
                                                        {e.english}
                                                    </div>
                                                    <div style={{
                                                        fontSize: 28
                                                    }}>{e.title}</div>
                                                    <br/>
                                                    <div style={{fontSize: 14, maxWidth: 300, minHeight: 60, textAlign: 'justify'}}>
                                                        {e.description}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    ),
                                )(
                                    [
                                        {
                                            id: 'single',
                                            title: "單品咖啡",
                                            description: "您可以選擇自己喜歡的時間訂閱我們的咖啡，我們會依照客人喜歡得風味，一個月推薦三款1/4磅的咖啡豆。如果沒有咖啡器具，我們會幫您做成掛耳式咖啡，方便在任何地方沖煮咖啡。",
                                            english: "Single Origin Coffee",
                                            imageUrl: 'https://cdn.shopify.com/s/files/1/0645/0436/5310/files/single-sub.jpg',
                                            productUrl: '/products/單品咖啡訂閱',
                                        },
                                        {
                                            id: 'espresso',
                                            title: "濃縮咖啡",
                                            description: "您可以自行選擇需要的磅數以及需要的時間訂閱義式咖啡豆，我們的義式配方豆適合任何方式沖煮，有兩款烘焙程度可以做選擇。",
                                            english: "Espresso blend",
                                            imageUrl: 'https://cdn.shopify.com/s/files/1/0645/0436/5310/files/espresso-sub.jpg',
                                            productUrl: '/products/濃縮咖啡訂閱',
                                        },
                                    ]
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
;

export default Subscribe;
